import React from "react";
import styled from "styled-components";
import "../App.scss";
import Button from "../components/Button";
import Card from "../components/Card";
import FlashMessage from "../components/FlashMessage";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import TextField from "../components/TextField";
import ActivateForm from "./ActivateForm";
import { signIn } from "./amplify";
import CompleteNewPasswordForm from "./CompleteNewPasswordForm";
import ResetPassForm from "./ResetPassForm";
import SignupForm from "./SignupForm";
import { captureException } from "../util/sentry";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const LoginButton = styled(Button)`
  margin-bottom: 4px;
  width: calc(100% - 16px);
`;

const FullWidthButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
`;

const ForgotPasswordButton = styled(LinkButton)`
  width: 100%;
  text-align: end;
  font-size: 90%;
  padding: 8px;
  width: 45%;
  margin-left: 55%;
`;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      submitting: false,
      error: undefined,
      form: "signup" | "forgot" | "change-password" | "activate",
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    this._showFormTimeout = setTimeout(
      () => this.setState({ visible: true }),
      1000
    );
    this.handleUrl();
    window.addEventListener("popstate", this.handlePopState);
  }

  handlePopState = () => {
    this.handleUrl();
  };

  handleUrl() {
    if (document.location.pathname.startsWith("/forgot-password")) {
      this.setState({ form: "forgot" });
      return;
    }
    if (document.location.pathname.startsWith("/signup")) {
      this.setState({ form: "signup" });
      return;
    }
    if (document.location.pathname.startsWith("/change-password")) {
      this.setState({ form: "change-password" });
      return;
    }
    this.setState({ form: undefined });
  }

  componentWillUnmount() {
    clearTimeout(this._showFormTimeout);
    window.removeEventListener("popstate", this.handlePopState);
  }

  onChange = (ev) => {
    // this.props.clearFlashMessage();
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  submit = async (ev) => {
    ev.preventDefault();
    const { username, password } = this.state;

    if (!username || !password) {
      return;
    }

    this.setState({
      submitting: true,
      error: undefined,
    });

    let user;

    try {
      user = await signIn(username.trim(), password);
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        this.setState({ form: "activate" });
        return;
      }
      if (
        ["UserNotFoundException", "NotAuthorizedException"].includes(e.code)
      ) {
        this.setState({ error: "Incorrect username or password." });
      } else {
        captureException("sign_in", e);
        this.setState({ error: "Could not log in, try again later." });
      }
    }
    this.setState({
      submitting: false,
      user,
    });

    if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
      window.history.pushState(
        {},
        "",
        new URL("change-password", window.location)
      );
      this.setState({
        form: "change-password",
      });
    }
  };

  signup = (ev) => {
    ev.preventDefault();
    window.history.pushState({}, "", new URL("signup", window.location));
    this.setState({
      form: "signup",
    });
  };

  forgotPass = (ev) => {
    ev.preventDefault();
    window.history.pushState(
      {},
      "",
      new URL("forgot-password", window.location)
    );
    this.setState({
      form: "forgot",
    });
  };

  clearFlashMessage = () => this.setState({ error: undefined });

  render() {
    if (!this.state.visible) return null;

    const { username, password, form, user } = this.state;

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    if (form === "signup") {
      return <SignupForm />;
    }

    if (form === "forgot") {
      return <ResetPassForm email={username} />;
    }

    if (form === "activate") {
      return <ActivateForm username={username} password={password} />;
    }

    if (form === "change-password") {
      return <CompleteNewPasswordForm user={user} />;
    }

    return (
      <Page>
        <Header />
        {this.state.error && (
          <FlashMessage
            message={this.state.error}
            isDanger={true}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Login"
            actionStacked={true}
            actionButtons={
              <LoginButton className={"mdc-button--raised"}>Log in</LoginButton>
            }
          >
            <StyledTextField
              className="username-input"
              type="email"
              id="username"
              hint="Email"
              onChange={this.onChange}
              value={username}
              autoComplete="username"
            />
            <StyledTextField
              className="password-input"
              type="password"
              id="password"
              onChange={this.onChange}
              hint="Password"
              value={password}
              autoComplete="current-password"
            />
            <input type="submit" hidden />
            <ForgotPasswordButton
              disabled={this.state.submitting}
              onClick={this.forgotPass}
            >
              Forgot your password?
            </ForgotPasswordButton>
          </Card>
        </form>
        <FullWidthButton onClick={this.signup}>Create account</FullWidthButton>
        <Footer />
      </Page>
    );
  }
}

export default LoginForm;
